/*
Color1: 42033d - Darkest Purple
Color2: 680e4b - Dark Purple
Color3: 7c238c - Purple
Color4: 854798 - Lighter Purple
Color5: 7c72a0 - Lightest/Graysih Purple
*/

h1 {
  padding: 5px;
  margin: 5px;
}

h2 {
  padding: 5px;
  margin: 5px;
}

h3 {
  padding: 5px;
  margin: 5px;
}

h4 {
  padding: 5px;
  margin: 5px;
}

h5 {
  padding: 5px;
  margin: 5px;
}

.app {
  display: grid;
  grid-template-rows: min-content auto;
  grid-template-columns: 100%;
  height: 100vh;
  color: #7c238c;
}

.columns-two {
  display: grid;
  grid-template-columns: 50% 50%;
  align-content: center;
  justify-items: center;
}

.header {
  display: flex;
  justify-content: space-between;
  grid-column: 1 / span 2;
  border-bottom: 1px solid #7c72a0;
  padding: 5px;
  font-weight: bold;
  /*box-shadow: 95px 5px 5px #7c72a0;*/
}

.body {
  display: grid;
  grid-template-columns: min-content auto;
}

.module-menu {
  display: flex;
  border-right: 1px solid #7c72a0;
  flex-direction: column;
  width: min-content;
  box-shadow: 5px 0px 5px #7c72a0;
}

.module-menu-item {
  min-width: 30px;
  padding: 30px;
  text-align: center;
}

.module-menu-item:hover {
  cursor: pointer;
  background-color: #854798;
}

.module-area {
  display: grid;
  padding: 10px;
}

button {
  all: unset;
}

.button-primary {
  margin: 5px;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid black;
  background-color: #7c238c;
  color: black;
  font-weight: bold;
}

.button-primary:hover {
  background-color: white;
  cursor: pointer;
}

.button-secondary {
  margin: 5px;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid black;
  background-color: #7c72a0;
  color: black;
  font-weight: bold;
}

.button-secondary:hover {
  background-color: white;
  cursor: pointer;
}

.panel {
  border-radius: 5px;
  border: 1px solid #7c72a0;
  padding: 10px;
  margin: 10px;
}

.panel-centered {
  display: flex;
  flex-direction: column;
  text-align: center;
  border: 1px solid #7c72a0;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 10px #7c72a0;
  padding: 20px;
  min-width: 50vh;
}

input {
  all: unset;
  padding: 5px;
  margin: 5px;
  border-radius: 5px;
  border: 1px solid black;
}

.popup-wrapper {
  position: absolute;
  z-index: 97;
  min-width: 100vw;
  min-height: 100vh;
}

.text {
  font-size: 10px;
  padding: 5px;
  margin: 5px;
}

.popup-background {
  position: absolute;
  z-index: 98;
  min-height: 100vh;
  min-width: 100vw;
  background-color: lightgrey;
  opacity: 50%;
}

.popup {
  position: absolute;
  opacity: 100%;
  top: 50%;
  left: 50%;
  margin-top: -25vh;
  margin-left: -25vh;
  z-index: 100;
  text-align: center;
  border: 1px solid #7c72a0;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 10px #7c72a0;
  padding: 20px;
  min-width: 50vh;
  background-color: white;
}
